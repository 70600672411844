/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import { Img } from "react-image";
import Spinner from "./Spinner";
import aboutPhoto from "../img/andy.jpg";

const About = () => {
  return (
    <div className="" id="about">
      <section className="aboutLeftCol">
        <div className="aboutPhoto">
          <Img
            src={aboutPhoto}
            alt="Andy Knolle portrait"
            id="andyPortrait"
            loader={<Spinner />}
          />
        </div>
        <article>
          Andy Knolle (he/him b. 1993) is an interdisciplinary artist currently
          working in Cincinnati, OH. He received his BFA in Studio Art and a
          Certificate in Critical Visions from the University of Cincinnati’s
          College of Design, Architecture, Art, and Planning (DAAP). He has
          shown nationally and internationally at Kunsthalle am Hamburger Platz,
          Berlin. Andy works with a process-driven practice to unpack the ideas
          of noise, art as technology, and our relation to communicative
          technologies.
        </article>
      </section>
      <section className="aboutRightCol">
        <div>
          <h2 className="aboutHeader">Education</h2>
          <ul>
            <li>
              <div className="aboutYear">2016</div>
              <div>
                <p>
                  University of Cincinnati, College of Design, Architecture,
                  Art, & Planning (DAAP), Cincinnati, OH
                </p>
                <p>BFA Studio Art, Certificate in Critical Visions</p>
              </div>
            </li>
          </ul>
          <h2 className="aboutHeader">Solo Exhibitions</h2>
          <ul>
            <li className="flex-dir-row">
              <div className="aboutYear">2019</div>
              <div className="some-margin-left">
                <p>Sub_ Divisions, OTOT Studios (Cincinnati, OH)*</p>
              </div>
            </li>
            <li className="flex-dir-row">
              <div className="aboutYear">2016</div>
              <div className="some-margin-left">
                <p>Noisy Relations, The 840 Gallery (Cincinnati, OH)</p>
              </div>
            </li>
            <li className="flex-dir-row">
              <div className="aboutYear">2014</div>
              <div className="some-margin-left">
                <p>//:gL/i_zch\|**, Public Installation (Cincinnati, OH)*</p>
              </div>
            </li>
          </ul>
          <p id="footnote">*As a Collaboration with Victoria Weird</p>
          <h2 className="aboutHeader">Selected Group Exhibitions</h2>
          <ul>
            <li className="flex-dir-row">
              <div className="aboutYear">2022</div>
              <div className="some-margin-left">
                <p>(Y)Our Community, Elevar Gallery (Cincinnati, OH)</p>
              </div>
            </li>
            <li className="flex-dir-row">
              <div></div>
              <div className="some-margin-left">
                <p>The Teachers Lounge, The Kennedy Gallery (Cincinnati, OH)</p>
              </div>
            </li>
            <li className="flex-dir-row">
              <div className="aboutYear">2021</div>
              <div className="some-margin-left">
                <p>Films Not Dead, The Lodge (Dayton, KY)</p>
              </div>
            </li>
            <li className="flex-dir-row">
              <div className="aboutYear">2019</div>
              <div className="some-margin-left">
                <p>Smelling Like Roses, Bunk Spot Gallery (Cincinnati, OH)</p>
              </div>
            </li>
            <li className="flex-dir-row">
              <div className="aboutYear">2018</div>
              <div className="some-margin-left">
                <p>UNFRAMED, Pike Street Gallery (Covington, KY)</p>
              </div>
            </li>
            <li className="flex-dir-row">
              <div className="aboutYear">2016</div>
              <div className="some-margin-left">
                <p>DAAP Works, The Meyers Gallery (Cincinnati, OH)</p>
                <p>SOA All-Stars, The Reed Gallery (Cincinnati, OH)</p>
              </div>
            </li>
            <li className="flex-dir-row">
              <div className="aboutYear">2015</div>
              <div className="some-margin-left">
                <p>
                  Broken Dreamz/ Constructed Realities, Kunstalle am Hamburger
                  Platz (Berlin DE)
                </p>
                <p>Morph 2.0, The 840 Gallery (Cincinnati, OH)</p>
              </div>
            </li>
            <li className="flex-dir-row">
              <div className="aboutYear">2013</div>
              <div className="some-margin-left">
                <p>The Beloved Blobject, The 840 Gallery (Cincinnati, OH)</p>
              </div>
            </li>
          </ul>
          <h2 className="aboutHeader">Performances</h2>
          <ul>
            <li className="flex-dir-row">
              <div className="aboutYear">2015</div>
              <div className="some-margin-left">
                <p>
                  Sound Reflections: Berlin, Max Kade Center of German- American
                  Studies (Cincinnati, OH)
                </p>
              </div>
            </li>
            <li className="flex-dir-row">
              <div className="aboutYear">2014</div>
              <div className="some-margin-left">
                <p>Cateyes’ Assorted Sounds, First Friday, (Cincinnati, OH)</p>
                <p>
                  Lush: MFA Exhibition (Guitar Player for Julie Anne Ward),
                  Contemporary Arts Center (Cincinnati, OH)
                </p>
              </div>
            </li>
          </ul>
          <h2 className="aboutHeader">Publications</h2>
          <ul>
            <li class="flex-dir-row">
              <div className="aboutYear">2022</div>
              <div class="some-margin-left">
                <p>
                  Eunoia, Psyop of the Year, Nefarious Industries (United
                  States)
                </p>
              </div>
            </li>
            <li class="flex-dir-row">
              <div className="aboutYear">2021</div>
              <div class="some-margin-left">
                <p>
                  Fourth Wife, Five Word and a Fake one, Culture Vacuum Records
                  (Cincinnati, OH)
                </p>
                <p>
                  Mockery, Racing Mind, Independent Release (Cincinnati, OH)
                </p>
              </div>
            </li>
            <li class="flex-dir-row">
              <div className="aboutYear">2020</div>
              <div class="some-margin-left">
                <p>
                  Mockery, Headcannon, Independent Release (Cincinnati, OH)*
                </p>
              </div>
            </li>
            <li class="flex-dir-row">
              <div className="aboutYear">2019</div>
              <div class="some-margin-left">
                <p>
                  Hissing Tiles, Boychoir, White Sepulcher Records (Cincinnati,
                  OH)*
                </p>
                <p>
                  Pout, Present &amp; Tense, Let's Pretend Records (Bloomington,
                  IN)*
                </p>
                <p>Marr, MARR, Independent Release (Cincinnati, OH)*</p>
              </div>
            </li>
            <li class="flex-dir-row">
              <div className="aboutYear">2018</div>
              <div class="some-margin-left">
                <p>
                  Santamania 2018, Independent Publication (Cincinnati, OH)*
                </p>
                <p>
                  Canvas &amp; Cassette, Issue 3, Independent Publication (New
                  York, NY)*
                </p>
              </div>
            </li>
            <li class="flex-dir-row">
              <div className="aboutYear">2016</div>
              <div class="some-margin-left">
                <p>
                  CVSN: A Field Guide to Color, Special Collections Press
                  (Cincinnati, OH)
                </p>
              </div>
            </li>
          </ul>
          <h2 className="aboutHeader">Press</h2>
          <ul>
            <li className="flex-dir-row">
              <div className="aboutYear">2022</div>
              <div className="some-margin-left">
                <p>Artswave- The Teachers Lounge- The Kennedy Gallery</p>
              </div>
            </li>
            <li className="flex-dir-row">
              <div></div>
              <div className="some-margin-left">
                <p>Happening Next- (Y)our Community- The Elavar Gallery</p>
              </div>
            </li>
            <li className="flex-dir-row">
              <div className="aboutYear">2015</div>
              <div className="some-margin-left">
                <p>The News Record- Artist Feature- Cincinnati, OH</p>
              </div>
            </li>
          </ul>
          <h2 className="aboutHeader">Discography</h2>
          <ul>
            <li>
              <div className="aboutYear">2014</div>
              <div class="some-margin-left">
                <p>
                  Cateyes’ Assorted Sounds, Cateyes’ Assorted Sounds,
                  Independent Release (Cincinnati, OH)
                </p>
              </div>
            </li>
          </ul>
        </div>
        <h2 className="aboutHeader">Experience</h2>
        <ul>
          <li className="flex-dir-row">
            <div className="aboutYear">2022</div>
            <div className="some-margin-left">
              <p>Studio Assistant to Gee Horton (Cincinnati, OH)</p>
              <p>Photo Assistant to Chris Cone (Cincinnati, OH)</p>
              <p>Photo Assistant to Lance Adkins (Cincinnati, OH)</p>
              <p>Photo Assistant Joshua Berg (Cincinnati, OH)</p>
              <p>Photo Assistant Todd Joyce (Cincinnati, OH)</p>
              <p>
                Digital Print Specialist, Dodd Camera, 2016-2022 (Cincinnati,
                OH)
              </p>
            </div>
          </li>
          <li className="flex-dir-row">
            <div className="aboutYear">2016</div>
            <div className="some-margin-left">
              <p>Studio Assistant to Jeanette Nuxol (Cincinnati, OH)</p>
              <p>
                Vice President, Student Artist Approaching Potential, DAAP
                School of Art 2015-2016 (Cincinnati, OH)
              </p>
              <p>
                Member, Student Artist Approaching Potential, DAAP School of Art
                2013-2016 (Cincinnati, OH)
              </p>
            </div>
          </li>
          <li className="flex-dir-row">
            <div className="aboutYear">2014</div>
            <div className="some-margin-left">
              <p>Studio Assistant to Kate Ball (Cincinnati, OH)</p>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default About;
