import nR1 from "../img/NoiseyRelations/NR-1-min.jpg";
import nR2 from "../img/NoiseyRelations/NR-2-min.jpg";
import nR3 from "../img/NoiseyRelations/NR-3-min.jpg";
import nR4 from "../img/NoiseyRelations/NR-4-min.jpg";
import nR5 from "../img/NoiseyRelations/NR-5-min.jpg";
import nR6 from "../img/NoiseyRelations/NR-6-min.jpg";
import nR7 from "../img/NoiseyRelations/NR-7-min.jpg";
import nR8 from "../img/NoiseyRelations/NR-8-min.jpg";
import nR9 from "../img/NoiseyRelations/NR-9-min.jpg";
import nR10 from "../img/NoiseyRelations/NR-10-min.jpg";
import nR11 from "../img/NoiseyRelations/NR-11-min.jpg";
import nR12 from "../img/NoiseyRelations/NR-12-min.jpg";
import nR13 from "../img/NoiseyRelations/NR-13-min.jpg";
import nR14 from "../img/NoiseyRelations/NR-14-min.jpg";

export const nRPics = [
  nR1,
  nR2,
  nR3,
  nR4,
  nR5,
  nR6,
  nR7,
  nR8,
  nR9,
  nR10,
  nR11,
  nR12,
  nR13,
  nR14,
];
