import saho1 from "../img/SAHO/SAHO-1-min.jpg";
import saho2 from "../img/SAHO/SAHO-2-min.jpg";
import saho3 from "../img/SAHO/SAHO-3-min.jpg";
import saho4 from "../img/SAHO/SAHO-4-min.jpg";
import saho5 from "../img/SAHO/SAHO-5-min.jpg";
import saho6 from "../img/SAHO/SAHO-6-min.jpg";
import saho7 from "../img/SAHO/SAHO-7-min.jpg";
import saho8 from "../img/SAHO/SAHO-8-min.jpg";
import saho9 from "../img/SAHO/SAHO-9-min.jpg";
import saho10 from "../img/SAHO/SAHO-10-min.jpg";
import saho11 from "../img/SAHO/SAHO-11-min.jpg";
import saho12 from "../img/SAHO/SAHO-12-min.jpg";
import saho13 from "../img/SAHO/SAHO-13-min.jpg";
import saho14 from "../img/SAHO/SAHO-14-min.jpg";
import saho15 from "../img/SAHO/SAHO-15-min.jpg";
import saho16 from "../img/SAHO/SAHO-16-min.jpg";
import saho17 from "../img/SAHO/SAHO-17-min.jpg";
import saho18 from "../img/SAHO/SAHO-18-min.jpg";
import saho19 from "../img/SAHO/SAHO-19-min.jpg";
import saho20 from "../img/SAHO/SAHO-20-min.jpg";

export const sahoPics = [
  saho1,
  saho2,
  saho3,
  saho4,
  saho5,
  saho6,
  saho7,
  saho8,
  saho9,
  saho10,
  saho11,
  saho12,
  saho13,
  saho14,
  saho15,
  saho16,
  saho17,
  saho18,
  saho19,
  saho20,
];
