import sD1 from "../img/SubDivisions/Sub-Divisions-1-min.jpg";
import sD2 from "../img/SubDivisions/Sub-Divisions-2-min.jpg";
import sD3 from "../img/SubDivisions/Sub-Divisions-3-min.jpg";
import sD4 from "../img/SubDivisions/Sub-Divisions-4-min.jpg";
import sD5 from "../img/SubDivisions/Sub-Divisions-5-min.jpg";
import sD6 from "../img/SubDivisions/Sub-Divisions-6-min.jpg";
import sD7 from "../img/SubDivisions/Sub-Divisions-7-min.jpg";
import sD8 from "../img/SubDivisions/Sub-Divisions-8-min.jpg";
import sD9 from "../img/SubDivisions/Sub-Divisions-9-min.jpg";
import sD10 from "../img/SubDivisions/Sub-Divisions-10-min.jpg";
import sD11 from "../img/SubDivisions/Sub-Divisions-11-min.jpg";
import sD12 from "../img/SubDivisions/Sub-Divisions-12-min.jpg";
import sD13 from "../img/SubDivisions/Sub-Divisions-13-min.jpg";
import sD14 from "../img/SubDivisions/Sub-Divisions-14-min.jpg";
import sD15 from "../img/SubDivisions/Sub-Divisions-15-min.jpg";
import sD16 from "../img/SubDivisions/Sub-Divisions-16-min.jpg";
import sD17 from "../img/SubDivisions/Sub-Divisions-17-min.jpg";

export const subDivPics = [
  sD1,
  sD2,
  sD3,
  sD4,
  sD5,
  sD6,
  sD7,
  sD8,
  sD9,
  sD10,
  sD11,
  sD12,
  sD13,
  sD14,
  sD15,
  sD16,
  sD17,
];
