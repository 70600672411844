import con1 from "../img/Constructs/CONSTRUCTS-1-min.jpg";
import con2 from "../img/Constructs/CONSTRUCTS-2-min.jpg";
import con3 from "../img/Constructs/CONSTRUCTS-3-min.jpg";
import con4 from "../img/Constructs/CONSTRUCTS-4-min.jpg";
import con5 from "../img/Constructs/CONSTRUCTS-5-min.jpg";
import con6 from "../img/Constructs/CONSTRUCTS-6-min.jpg";
import con7 from "../img/Constructs/CONSTRUCTS-7-min.jpg";
import con8 from "../img/Constructs/CONSTRUCTS-8-min.jpg";

export const constructsPics = [con1, con2, con3, con4, con5, con6, con7, con8];
