import cd1 from "../img/Chromatic Delineation/CD-1-min.jpg";
import cd2 from "../img/Chromatic Delineation/CD-2-min.jpg";
import cd3 from "../img/Chromatic Delineation/CD-3-min.jpg";
import cd4 from "../img/Chromatic Delineation/CD-4-min.jpg";
import cd5 from "../img/Chromatic Delineation/CD-5-min.jpg";
import cd6 from "../img/Chromatic Delineation/CD-6-min.jpg";
import cd7 from "../img/Chromatic Delineation/CD-7-min.jpg";
import cd8 from "../img/Chromatic Delineation/CD-8-min.jpg";
import cd9 from "../img/Chromatic Delineation/CD-9-min.jpg";

export const cdPics = [cd1, cd2, cd3, cd4, cd5, cd6, cd7, cd8, cd9];
